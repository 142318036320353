import { createApp } from "vue";

import NavSearch from "../views/NavSearch.ce.vue";
import { createPinia } from "pinia";

customElements.define('navsearch-component', class extends HTMLElement {
    connectedCallback() {
        const container = document.createElement('div');
        container.id = 'app';
        this.appendChild(container);
        createApp(NavSearch).use(createPinia()).mount(container);
    }
});
