<template>
    <div class="navsearch__wrapper" @keydown="handleKeyDown">
        <div class="searchinput">
            <input
            @keyup.enter="handleSearch"
            @input="handleTypeahead"
            v-model="internalSearchInput"
            id="navsearch"
            type="text"
            ref="inputRef"
            placeholder="Suchbegriff..."
            autocomplete="off"
            aria-label="Suchfeld.">
            <button @click="handleSearch" aria-label="Suche starten.">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.99999 8C2.99999 6.67392 3.52677 5.40215 4.46446 4.46447C5.40214 3.52678 6.67391 3 7.99999 3C9.32607 3 10.5978 3.52678 11.5355 4.46447C12.4732 5.40215 13 6.67392 13 8C13 9.32608 12.4732 10.5979 11.5355 11.5355C10.5978 12.4732 9.32607 13 7.99999 13C6.67391 13 5.40214 12.4732 4.46446 11.5355C3.52677 10.5979 2.99999 9.32608 2.99999 8ZM7.99999 1C6.89125 1 5.79837 1.26338 4.81134 1.76843C3.82431 2.27349 2.97138 3.00578 2.32276 3.90501C1.67415 4.80423 1.24842 5.84467 1.08063 6.94064C0.912832 8.03661 1.00778 9.15676 1.35764 10.2089C1.7075 11.2609 2.30228 12.2149 3.09298 12.9921C3.88369 13.7693 4.84771 14.3476 5.90567 14.6794C6.96362 15.0111 8.08524 15.0868 9.17816 14.9001C10.2711 14.7135 11.304 14.27 12.192 13.606L17.292 18.707C17.4795 18.8946 17.7339 19.0001 17.9991 19.0002C18.2644 19.0003 18.5188 18.895 18.7065 18.7075C18.8941 18.52 18.9996 18.2656 18.9997 18.0004C18.9998 17.7351 18.8945 17.4806 18.707 17.293L13.607 12.193C14.3853 11.1525 14.8587 9.91592 14.9741 8.62167C15.0895 7.32741 14.8424 6.02658 14.2604 4.86481C13.6784 3.70304 12.7846 2.72618 11.6789 2.0436C10.5732 1.36103 9.29938 0.999674 7.99999 1Z" fill="#B9B9B9" stroke="white" stroke-width="0.5"/>
                </svg>
            </button>
        </div>
        <div v-if="typeaheadOpen" class="typeahead">
            <template v-if="!typeaheadLoading">
                <div v-for="(suggestion, index) of typeaheadResults" :key="suggestion">
                    <button :class="{'typeahead--highlight' : index == focusIndex}" ref="suggestionRefs" @click="acceptTypeahead(suggestion)">{{ suggestion }}</button>
                </div>
            </template>
            <div v-else>
                <div class="loading">
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        </div>
        <div v-if="typeaheadOpen" class="clickoutside" @click="typeaheadOpen = false"></div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { nextTick } from 'vue';

const url = new URL(window.location.origin + "/alle-bildungsangebote");
const params = new URLSearchParams(url.search);

const internalSearchInput = ref("");

const typeaheadOpen = ref(false);
const typeaheadLoading = ref(false);
const typeaheadResults = ref([]);

const blockTypeahead = ref(false);

const suggestionRefs = ref([]);
const inputRef = ref(null);
const focusIndex = ref(-1);

let debounceTimeout;

const handleTypeahead = async () => {
    if(blockTypeahead.value) {
        return;
    }
    if(internalSearchInput.value){
        typeaheadOpen.value = true;
    } else {
        typeaheadOpen.value = false;
    }
    if(debounceTimeout) {
        clearTimeout(debounceTimeout);
    }
    
    debounceTimeout = setTimeout(async () => {


        typeaheadLoading.value = true;
        try {
            const response = await fetch(`/api/search/search-type-ahead.json?type=event&search=${internalSearchInput.value}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const result = await response.json();

            focusIndex.value = -1;

            if(response.ok) {
                if(result.length != 0) {
                    typeaheadResults.value = [...result];
                } else {
                    typeaheadResults.value = ["Keine Ergebnisse gefunden"];
                }
            } else {
                throw new Error("Request failed: " + result.status)
            }
        } catch (err) {
            typeaheadResults.value = ["Server antwortet nicht"];
            console.error(err);
        } finally {
            typeaheadLoading.value = false;
        }


    }, 300)

}

const handleKeyDown = (event) => {
    if(event.key === "ArrowDown") {
        blockTypeahead.value = true;
        focusIndex.value = Math.min(focusIndex.value + 1, suggestionRefs.value.length - 1);
        nextTick(() => {
            internalSearchInput.value = suggestionRefs.value[focusIndex.value].innerHTML
        })
        
    } else if(event.key === "ArrowUp") {
        blockTypeahead.value = true;
        focusIndex.value = Math.max(focusIndex.value - 1, 0);
        nextTick(() => {
            internalSearchInput.value = suggestionRefs.value[focusIndex.value].innerHTML
            if (inputRef.value) {
                setTimeout(() => {
                    inputRef.value.selectionStart = inputRef.value.selectionEnd = 10000;
                },0)
            }
        })
    }
    setTimeout(() => {
        blockTypeahead.value = false;
    }, 100)
}

const acceptTypeahead = (suggestion) => {
    internalSearchInput.value = suggestion;
    typeaheadOpen.value = false;
    handleSearch();
}

watch(() => internalSearchInput.value, newVal => {
    if(newVal == "") {
        params.delete("search");
    }
})

const handleSearch = () => {
    params.set("eventFilter", 'BKh4AGK8A8ttX8mqv6cfew2dH');
    params.set("search", internalSearchInput.value);
    params.set("searchMode", true)
    
    url.search = params.toString();

    window.location.href = url;
}


</script>

<style lang="scss" scoped>

</style>
